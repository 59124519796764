<template>

    <div class="page-container">
        
        <!-- <Header ref="header" @sendCmd="sendCmd" :showPages="true" :showCmd="true"></Header> -->
        <Header ref="header" 
            @sendCmd="sendCmd"
            :showCmd="true"
            :showPages="true"
            :examId="examId"
            :paperId="paperId"
            :qid="qid"
        ></Header>
        <Scratch ref="page" />

    </div>

</template>

<script>
import Header from '@/views/pages/ide/exam/Header'
// import Header from "@/views/pages/exam/Header";
import Scratch from '@/views/pages/ide/exam/scratch/Scratch'
import { mapGetters } from 'vuex'
import { getExamData } from "@/api/exam";

export default {
    name: 'exam-scratch',
    data: function() {
        return {
            examId: "",
            paperId: "",
            qid: "",
        }
    },
    created() {
    },
    activated() {
    },
    computed: {
    },
    beforeRouteUpdate(to, from, next) {
        // 解决路由重复问题，从to中读取参数，手动更新页面
        this.examId = to.query.examId
        this.paperId = to.query.paperId
        this.qid = to.query.qid

        this.loadData()

        next()
    },
    mounted() {
        // 加载页面参数
        if (this.$route.query.examId) {
            this.examId = this.$route.query.examId
        }
        if (this.$route.query.paperId) {
            this.paperId = this.$route.query.paperId
        }
        if (this.$route.query.qid) {
            this.qid = this.$route.query.qid
        }
        this.loadData()
    },
    methods: {
        sendCmd(data) {
            this.$refs.page.handleCmd(data)
        },
        async loadData() {
            // 当前环境
            let curEnv = 'exam'

             // 加载考试信息
            if (this.examId != '') {
                this.loadExamInfo(this.examId)
            }
            if (this.paperId != '') {
                await this.$store.dispatch('exam/GetPaperData', this.paperId)
            }
            // 加载数据
            this.$refs.page.loadPage(this.examId, this.qid, curEnv)
        },
        async loadExamInfo(examId) {
            let res = await getExamData(examId);
            if (res.data.status == 2) {
                this.$router.replace({ path: '/exam/end' });
            }

            this.$store.commit("exam/SetExamInfo", {
                examId: res.data.examId,
                examTitle: res.data.examTitle,
                examName: res.data.examName,
                startTime: res.data.startTime,
                endTime: res.data.endTime,
                count: res.data.count,
                questionIds: res.data.questionIds,
                questionTypes: res.data.questionTypes,
            });

            this.$refs.header.initClock(res.data.startTime, res.data.endTime);
        },
    },
    components: {
        Header,
        Scratch
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #222;
}
</style>
