<template>

    <div v-show="ide.dialog.isShowDeleteActorDlg" class="deleteDialog">
        <div class="screen-mask"></div>
        <div class="dialog">
            <div class="title">
                <img src='@/assets/images/project/delete2.svg'>
                <!-- <div class="text">确定要删除这个角色么？</div> -->
                <div class="text">{{ modalDlg.title }}</div>
            </div>
            <!-- <div class="content">角色《{{actorName}}》的造型及对应代码将被删除</div> -->
            <div class="content">
                <!-- {{ modalDlg.content }} -->
                <span v-html="highlight(modalDlg)"></span>
            </div>
            <div class="btn-row">
                <div class="cancel" @click.stop="cancel()">
                    <img src='@/assets/images/project/cancel.svg'>
                    <div class="text">取消</div>
                </div>
                <div class="confirm" @click.stop="confirm()">
                    <img src="@/assets/images/project/confirm.svg">
                    <div class="text">确定</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'deleteDialog',
        props: [
            'actorName'
        ],
        computed: {
            ...mapGetters([
                'ide',
                'modalDlg'
            ]),
        },
        created() {
        },
        activated() {
        },
        watch: {
        },
        methods: {
            cancel() {
                this.$store.commit('web/CloseModalDlg');
            },
            confirm() {
                if (this.modalDlg.okCallback) {
                    this.modalDlg.okCallback();
                }
                this.$store.commit('web/CloseModalDlg');
            },
            highlight(modalDlg){
                if (modalDlg.content.includes(modalDlg.highlight)){
                    return modalDlg.content.replace(
                        modalDlg.highlight, 
                        // 这里是替换成html格式的数据，最好再加一个样式权重，保险一点
                        '<span style="color: #ff8400 !important;">'+ modalDlg.highlight +'</span>'
                    )
                }
                // 不包含的话还用这个
                else {
                    return modalDlg.content
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.deleteDialog {
  .screen-mask {
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    opacity: 1;
    visibility: visible;
  }

  .dialog {
    position: fixed;
    z-index: 2001;
    //width: 750px;
    top: 35%;
    left: 50%;
    //margin-top: -250px;
    margin-left: -162px;

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      letter-spacing: 1px;

      img {
        height: 45px;
        width: 45px;
        margin-right: 15px;
        margin-bottom: 2px;
      }
      .text {
        font-size: 21px;
        color: #ffffff;
      }
    }

    .content {
      font-size: 18px;
      color: #ffffff;
      margin: 40px 0 50px 0;
      letter-spacing: .5px;
    }

    .btn-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 90px;
        font-size: 16px;
        line-height: 36px;
        text-align: center;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 4px;
        margin-right: 20px;
        cursor: pointer;

        img {
          height: 17px;
          width: 17px;
          margin-right: 8px;
        }
      }

      .confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 90px;
        font-size: 16px;
        line-height: 36px;
        text-align: center;
        color: #2bcc40;
        border: 1px solid #2bcc40;
        border-radius: 4px;
        cursor: pointer;

        img {
          height: 20px;
          width: 20px;
          margin-right: 8px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>