<template>

    <div class="property-area">
        <div class="item">
            <div class="text">当前角色</div>
            <input type="text" class="name" v-model.lazy.number="propName">
        </div>
        <div class="item-img" style="margin-left: 5px;">
            <img src="@/assets/images/common/btn-eye-hide.svg" style="height: 22px;margin-left: -1px;" @click.stop="setShow('true')" v-if="curActor.isShow == 'false'">
            <img src="@/assets/images/common/btn-eye-show.svg" style="height: 13px;margin-top: 5px;" @click.stop="setShow('false')" v-else >
        </div>
        <div class="item">
            <div class="text">X</div>
            <input type="text" class="number-input" v-bind:value="curActor.x" 
                @blur="enter('x', $event)" @keyup.enter="enter('x', $event)" @keyup.up="enterUp('x', $event)" @keyup.down="enterDown('x', $event)">
        </div>
        <div class="item">
            <div class="text">Y</div>
            <input type="text" class="number-input" v-bind:value="curActor.y" 
                @blur="enter('y', $event)" @keyup.enter="enter('y', $event)" @keyup.up="enterUp('y', $event)" @keyup.down="enterDown('y', $event)">
        </div>
        <div class="item">
            <div class="text">大小</div>
            <input type="text" class="number-input" v-bind:value="curActor.size" 
                @blur="enter('size', $event)" @keyup.enter="enter('size', $event)" @keyup.up="enterUp('size', $event)" @keyup.down="enterDown('size', $event)">
        </div>
        <div class="item">
            <div class="text">方向</div>
            <input type="text" class="number-input" v-bind:value="curActor.degree" 
                @blur="enter('degree', $event)" @keyup.enter="enter('degree', $event)" @keyup.up="enterUp('degree', $event)" @keyup.down="enterDown('degree', $event)">
        </div>

        <div class="item">
            <div class="text" style="margin-right: 3px;">翻转</div>
        </div>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <div class="item-img" style="margin-left: 5px;" v-bind="attrs" v-on="on" >
                    <img src="@/assets/images/common/btn-flip-horizonal-active.svg" style="height: 13px;margin-top: 5px;" @click.stop="setFlip('isFlipHorizontal', 'false')" v-if="curActor.isFlipHorizontal == 'true'" >
                    <img src="@/assets/images/common/btn-flip-horizonal.svg" style="height: 13px;margin-top: 5px;" @click.stop="setFlip('isFlipHorizontal', 'true')" v-else >
                </div>
            </template>
            <span>水平翻转</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <div class="item-img" style="margin-left: 5px;" v-bind="attrs" v-on="on" >
                    <img src="@/assets/images/common/btn-flip-vertical-active.svg" style="height: 13px;margin-top: 5px;" @click.stop="setFlip('isFlipVertical', 'false')" v-if="curActor.isFlipVertical == 'true'" >
                    <img src="@/assets/images/common/btn-flip-vertical.svg" style="height: 13px;margin-top: 5px;" @click.stop="setFlip('isFlipVertical', 'true')" v-else >
                </div>
            </template>
            <span>垂直翻转</span>
        </v-tooltip>

        <div class="item">
            <div class="text" style="margin-right: 3px;">旋转模式</div>
        </div>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <div class="item-img" v-bind="attrs" v-on="on" >
                    <img src="@/assets/images/common/btn-rotation-1-active.svg" style="height: 13px;" v-show="curActor.rotationMode == 0">
                    <img src="@/assets/images/common/btn-rotation-1.svg" style="height: 13px;" v-show="curActor.rotationMode != 0" @click.stop="setRotate(0)">
                </div>
            </template>
            <span>任意旋转</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <div class="item-img" v-bind="attrs" v-on="on" >
                    <img src="@/assets/images/common/btn-rotation-2-active.svg" style="height: 13px;" v-show="curActor.rotationMode == 1">
                    <img src="@/assets/images/common/btn-rotation-2.svg" style="height: 13px;" v-show="curActor.rotationMode != 1" @click.stop="setRotate(1)">
                </div>
            </template>
            <span>左右翻转</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <div class="item-img" v-bind="attrs" v-on="on" >
                    <img src="@/assets/images/common/btn-rotation-3-active.svg" style="height: 13px;" v-show="curActor.rotationMode == 2">
                    <img src="@/assets/images/common/btn-rotation-3.svg" style="height: 13px;" v-show="curActor.rotationMode != 2" @click.stop="setRotate(2)">
                </div>
            </template>
            <span>不可旋转</span>
        </v-tooltip>

    </div>
</template>

<script>
    import CoreData from '@/libs/runtime/coreData.js';
    import { mapGetters } from 'vuex';

    export default {
        name: 'dataArea',
        props: [
        ],
        data: function () {
            return {
                img: {
                    logo: '@/assets/images/common/logo_white.png',
                },
            }
        },
        computed: {
            ...mapGetters([
                'curActor',
            ]),
            propName: {
                get() {
                    return this.curActor.name;
                },
                set (newVal) {
                    this.$store.commit('web/SetCurActorInfo', { name: 'name', value: newVal });
                    CoreData.setMeta(this.curActor.id, 'name', newVal);
                    // 更新角色区显示
                    this.$store.commit('web/SetActorAreaData', { id: this.curActor.id, name: newVal, src: null });
                }
            },
        },
        mounted() {
            window.updateMeta = (actorId, meta) => {
                this.updateMeta(actorId, meta);
            };
        },
        created() {
        },
        activated() {
        },
        watch: {
        },
        methods: {
            enter(name, event) {
                let newVal = parseInt(event.currentTarget.value);
                this.setMeta(name, newVal);

                //解除焦点
                event.target.blur();
            },
            enterUp(name, event) {
                let newVal = parseInt(event.currentTarget.value) + 1;
                this.setMeta(name, newVal);
            },
            enterDown(name, event) {
                let newVal = parseInt(event.currentTarget.value) - 1;
                this.setMeta(name, newVal);
            },
            setMeta(name, value) {
                // 数据限制
                if (name == 'size') {
                    if (value < 0) {
                        value = 0;
                    }
                }
                if (name == 'degree') {
                    while (value > 180) {
                        value -= 360;
                    }
                    while (value <= -180) {
                        value += 360;
                    }
                }

                this.$store.commit('web/SetCurActorInfo', { name: name, value: value });
                CoreData.setMeta(this.curActor.id, name, value);
                window.spriteInstance.setMeta(this.curActor.id, name, value);
            },

            //角色的显示/隐藏
            setShow(isShow) {
                this.$store.commit('web/SetCurActorInfo', { name: 'isShow', value: isShow });

                CoreData.setMeta(this.curActor.id, 'isShow', isShow);
                window.spriteInstance.setMeta(this.curActor.id, 'isShow', isShow);
            },
            //角色的水平/垂直翻转
            setFlip(flipName, isFlipped) {
                this.$store.commit('web/SetCurActorInfo', { name: flipName, value: isFlipped });

                CoreData.setMeta(this.curActor.id, flipName, isFlipped);
                window.spriteInstance.setMeta(this.curActor.id, flipName, isFlipped);
            },
            //旋转角度
            setRotate(rotationMode) {
                this.$store.commit('web/SetCurActorInfo', { name: 'rotationMode', value: rotationMode });

                CoreData.setMeta(this.curActor.id, 'rotationMode', rotationMode);
                window.spriteInstance.setMeta(this.curActor.id, 'rotationMode', rotationMode);
            },

            // 当在展示区（egret）中调整角色后，同步更新当前角色属性的数据
            updateMeta(actorId, meta) {
                let curData = CoreData.getCurData();
                let actor = curData.actors[actorId];
                if (actor) {
                    for (let name in meta) {
                        if (name == 'x' || name == 'y' || name == 'size' || name == 'degree') {
                            // 使用整数值的参数
                            let val = Math.round(meta[name]);
                            // 方向：-179 到 +180
                            if (name == 'degree') {
                                while (val > 180) {
                                    val -= 360;
                                }
                                while (val <= -180) {
                                    val += 360;
                                }
                            }
                            // 更新角色区
                            this.$store.commit('web/SetCurActorInfo', { name: name, value: val });
                            // 更新CoreData
                            curData.actors[actorId].meta[name] = val;

                        } else {
                            // 更新CoreData，使用原始值
                            curData.actors[actorId].meta[name] = meta[name];
                        }
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.property-area {
    width: 100%;
    height: 40px;
    background-color: #474747;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    input[type="text"] {
        outline: none;
        background-color: #515151;
        color: #e6e6e6;
        border: 0;
        height: 20px;
        // width: 80px;
        font-size: 14px;
        padding: 1px 8px;
        border-radius: 2px;
    }
    input:focus {
        color: #515151;
        background-color: #ffffff;
    }

    .item {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 15px;

        img {
            height: 18px;
            margin-right: 5px;
        }

        .text {
            font-size: 14px;
            color: #e6e6e6;
            margin-right: 8px;
        }

        .name {
            width: 80px;
        }

        .number-input {
            width: 55px;
            text-align: center;
        }
    }

    .item-img {
        height: 22px;
        width: 22px;
        // padding: 0 6px;
        margin-left: 7px;
        img {
            height: 100%;
            cursor: pointer;
        }
    }
}
</style>
