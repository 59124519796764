<template>
    <div class="object-mask" v-if="current == 'demo' && !isShowDemoCode">
        <div class="screen-mask"></div>
        <div class="container">
            <div class="logo">
                <img src="@/assets/images/common/logo_white_no_text.png" />
            </div>
            <div class="text">科技改变世界，科技改变中国</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "cmdMask",
    computed: {
        ...mapGetters(["current", "isShowDemoCode"]),
    },
    created() {},
    activated() {},
    watch: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.object-mask {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #000;

    .screen-mask {
        position: absolute;
        z-index: 2000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // background: rgba(50, 50, 50, 0.95);
        background: rgba(50, 50, 50, 1);
        opacity: 1;
        visibility: visible;
        //cursor: pointer;
    }

    .container {
        position: absolute;
        z-index: 2001;
        // top: 40%;
        // left: 50%;
        right: 30px;
        bottom: 10px;
        width: 245px;
        //height: 210px;
        text-align: center;
        //margin-top: -105px;
        // margin-left: -122px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo {
            //height: 200px;
            width: auto;
            img {
                height: 100%;
                width: 100%;
            }
        }

        .text {
            font-size: 15px;
            font-weight: bold;
            //color: #dfdfdf;
            color: #e6e6e6;
            letter-spacing: 2px;
        }
    }
}
</style>