<template>
    <v-dialog v-model="dialog" open-delay="0">
        <div class="variableDialog" id="variableDialog">
            <div class="dialog">
                <div class="header">
                    <div class="title">{{ title }}</div>
                    <div class="window-close" @click.stop="cancel()">
                        <img src="@/assets/images/common/close.svg" />
                    </div>
                </div>
                <div class="content">
                    <div class="row left">
                        <div class="varLabel">{{ message }}</div>
                    </div>
                    <div class="row left">
                        <v-text-field
                            v-model="varName"
                            type="text"
                            single-line
                            placeholder="在此输入变量名"
                            clear-icon="mdi-close-circle"
                            clearable
                            outlined
                            autofocus
                            color="blue"
                            dense
                            hide-details="true"
                            @keydown="keyDown"
                        ></v-text-field>
                    </div>
                    <div class="row between">
                        <div class="option" @click.stop="setRadio('global')">
                            <input type="radio" v-model="scope" value="global" :disabled="!isNew" />
                            <div class="radioLabel">适用于所有角色</div>
                        </div>
                        <div class="option" @click.stop="setRadio('local')">
                            <input type="radio" v-model="scope" value="local" :disabled="!isNew" />
                            <div class="radioLabel">仅适用于当前角色</div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <input type="radio" v-model="isCloud" value="false">
                        <div class="radioLabel">本地变量</div>
                        <div class="seperator"></div>
                        <input type="radio" v-model="isCloud" value="true">
                        <div class="radioLabel">云变量</div>
                    </div> -->
                </div>
                <div class="btn-row">
                    <div class="cancel" @click.stop="cancel()">取消</div>
                    <div class="submit" @click.stop="submit()">确定</div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: "variableDialog",
    data: function () {
        return {
            dialog: false,

            title: "",
            message: "",

            resolve: "",
            reject: "",

            varName: "",
            varType: "var",
            scope: "global",
            isCloud: false,

            isNew: true,
        };
    },
    methods: {
        /**
         * 确定,将promise断定为完成态
         */
        submit() {
            this.dialog = false;

            this.resolve({
                name: this.varName,
                scope: this.scope,
                isCloud: this.isCloud,
            });
        },
        /**
         * 关闭,将promise断定为reject状态
         */
        cancel() {
            this.dialog = false;
            this.reject("cancel");
        },
        /**
         * 显示confirm弹出,并创建promise对象
         */
        showDlg(title, message, defaultValue, varType) {
            this.title = title;
            this.message = message;

            this.scope = "global";
            this.varName = "";
            this.varType = varType;

            this.isNew = defaultValue == '';

            this.dialog = true

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        setRadio(scope) {
            if (this.isNew) {
                this.scope = scope;
            }
        },
        keyDown(e) {
            if (e.key == 'Enter') {
                this.submit()
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.variableDialog {
    .dialog {
        position: fixed;
        z-index: 2001;
        display: flex;
        flex-direction: column;
        //width: 750px;
        top: 25%;
        left: 50%;
        //margin-top: -250px;
        margin-left: -210px;
        background: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 420px;

        .header {
            background: #1867c0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            height: 50px;

            .title {
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                letter-spacing: 1px;
            }

            .window-close {
                position: absolute;
                // top: 0;
                right: 5px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px;
                border-radius: 30px;

                img {
                    height: 14px;
                    width: 14px;
                }
            }

            .window-close:hover {
                background-color: #416fc4;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            padding: 30px 40px 10px 40px;
        }

        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            &.left {
                justify-content: flex-start;
            }

            &.right {
                justify-content: flex-end;
            }

            &.between {
                justify-content: space-between;
            }
        }

        .btn-row {
            display: flex;
            justify-content: flex-end;
            margin: 0 15px 15px 15px;
        }

        .option {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
        }

        .varLabel {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.5px;
            color: #333;
        }

        .radioLabel {
            font-size: 15px;
            color: #333;
            margin-left: 3px;
        }

        input[type="radio"] {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        .cancel {
            padding: 8px 15px;
            border-radius: 4px;
            color: #1867c0;
            cursor: pointer;
        }
        .cancel:hover {
            background-color: #f0f4fd;
        }

        .submit {
            padding: 8px 15px;
            color: #1867c0;
            border-radius: 4px;
            cursor: pointer;
        }
        .submit:hover {
            background-color: #f0f4fd;
        }
    }
}
</style>