<template>
    <div class="main-area forV">
        <div id="left-area" class="left-area" ref="leftArea" v-bind:style="{width: leftAreaWidth + 'px'}">
            <div id="player-area" ref="playerArea" class="player-area forV">
                <div id="egret-player" class="egret-player"
                        data-entry-class="Main"
                        data-orientation="auto"
                        data-scale-mode="fixedWidth"
                        data-frame-rate="30"
                        data-content-width="100%"
                        data-content-height="100%"
                        data-show-paint-rect="false"
                        data-multi-fingered="2"
                        data-show-fps="false" data-show-log="false"
                        data-show-fps-style="x:0,y:0,size:12,textColor:0xffffff,bgAlpha:0.9">
                </div>
                <AskAndAnswerDlg ref="askAndAnswerDlg" @submit="submitAnswer" ></AskAndAnswerDlg>
            </div>
            <div class="cmd-area">
                <div class="cmd-left">
                    <!--<div class="cmd-item">-->
                        <!--<img src="@/assets/images/ide/scale.png">-->
                        <!--<div class="text">{{ $t("IDE.STAGE_RATIO") }}</div>-->
                    <!--</div>-->

                    <!-- 标尺 -->
                    <div class="cmd-item" @click.stop="toggleRuler()">
                        <img src="@/assets/images/ide/ruler.svg" style="height: 17px;">
                        <div class="text">{{ $t("IDE.RULER") }}</div>
                    </div>

                    <!-- 坐标 -->
                    <div class="cmd-item" @click.stop="toggleMousePos()">
                        <img src="@/assets/images/ide/pos.svg" style="height: 20px;">
                        <div class="text">{{ $t("IDE.POS") }}</div>
                    </div>

                    <!-- 速度控制 -->
                    <v-menu
                        top
                        :close-on-click="closeOnClick"
                        @input="clickSpeedMenu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div class="cmd-item" v-bind="attrs" v-on="on" >
                                <img src="@/assets/images/ide/speed.svg" style="height: 19px;">
                                <div class="text">{{ speedTitle }}</div>
                            </div>
                        </template>
                        <v-list>
                            <v-list-item-group
                                v-model="selectedSpeedItem"
                                color="primary"
                            >
                                <v-list-item
                                    v-for="(item, index) in speedItems"
                                    :key="index"
                                >
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>
                
                <div v-show="isRunning" class="btn-stop" @click="stop()">
                    <img src="@/assets/images/ide/stop.svg">
                    <div class="text">{{ $t("IDE.STOP") }}</div>
                </div>
                <div v-show="!isRunning && isReset" class="btn-play" @click="play()">
                    <img src="@/assets/images/ide/play2.svg">
                    <div class="text">{{ $t("IDE.RUN") }}</div>
                </div>
                <div v-show="!isRunning && !isReset" class="btn-reset" @click="reset()">
                    <img src="@/assets/images/ide/reset.svg">
                    <div class="text">{{ $t("IDE.RESET") }}</div>
                </div>
            </div>
        </div>

        <div id="left-right-border" class="left-right-border forV"></div>

        <div id="right-area" class="right-area">
            <div id="blockly-area" class="blockly-area" ref="blocklyArea" >
                <div class="cur-obj" v-if="showToolbox">
                    <div class="triangle-right"></div>
                    <div class="text">{{curActor.name}}</div>
                </div>
                <div class="history" v-if="showToolbox">
                    <div class="btn-history" @click="undo()">
                        <img src="@/assets/images/ide/back.png" v-if="showHistoryBack">
                        <img src="@/assets/images/ide/back_default.png" v-else>
                    </div>
                    <div class="btn-history" @click="redo()">
                        <img src="@/assets/images/ide/forward.png" v-if="showHistoryForward">
                        <img src="@/assets/images/ide/forward_default.png" v-else>
                    </div>
                </div>

                <!-- 命令区 -->
                <div id="blockly-div" class="blockly-div"></div>

                <!-- 代码区 -->
                <div v-show="ide.area.isShowCodeArea" id="code-area" class="code-area">
                    <pre id="code-editor" class="code-editor"></pre>
                </div>
            </div>

            <!-- 角色属性区 -->
            <PropertyArea></PropertyArea>
            <!-- 数据区 -->
            <DataArea></DataArea>

            <!-- Masks -->
            <CmdMask></CmdMask>
            <!-- <RunningMask :isRunning="isRunning" @stop="stop"></RunningMask> -->

        </div>

        <VariableDlg ref="refVarDlg"></VariableDlg>
        <FunctionDialog ref="refFuncDlg"></FunctionDialog>

        <!--素材库窗口-->
        <MediaLib/>

        <!--属性设置及添加造型&声音窗口-->
        <Configure/>

        <!--删除角色对话框-->
        <DeleteDialog/>

    </div>
</template>

<script>
    import CoreData from '@/libs/runtime/coreData.js';
    import Ide from '@/libs/ide/ide.js';
    import Runner from '@/libs/runtime/runner.js';

    // 不要删除，Play中用到了！！！
    import CodeInterpreter from '@/libs/runtime/codeInterpreter.js';
    import SoundInstance from '@/libs/common/sound.js';

    // 引导类，用于展示区吸取颜色
    import Driver from "driver.js";
    import "driver.js/dist/driver.min.css";

    import PropertyArea from './PropertyArea';
    import DataArea from './DataArea';
    import CmdMask from '@/views/components/mask/CmdMask';
    // import RunningMask from '@/views/components/mask/RunningMask';
    import VariableDlg from '@/views/components/dialog/VariableDlg';
    import FunctionDialog from '@/views/components/dialog/FunctionDialog';
    import Configure from '@/views/components/window/Configure';
    import MediaLib from '@/views/components/window/MediaLib';
    import DeleteDialog from '@/views/components/dialog/DeleteDialog';
    import AskAndAnswerDlg from '@/views/components/dialog/AskAndAnswerDlg';

    import { mapGetters } from 'vuex';
    import { isEmptyObject,getImgUrl2 } from '@/utils/util'

    /* eslint-disable no-undef */

    export default {
        name: 'mainArea',
        props: {
            showToolbox: {
                type: Boolean,
                default: true,
            },
        },
        data: function() {
            return {
                showHistoryBack: false,
                showHistoryForward: false,

                leftAreaWidth: 450,

                resolve: null,
                reject: null,

                driver: null,

                selectedSpeedItem: 2,
                speedItems: [
                    { title: '非常快' },
                    { title: '快速' },
                    { title: '中速' },
                    { title: '慢速' },
                ],
                closeOnClick: true,
            }
        },
        created() {
            this.initHooker()
        },
        mounted() {
            // window.onbeforeunload = function() {
            //     return "确定要离开当前页面吗？";
            // }
            window.addEventListener("beforeunload", this.actBeforeUnload, false);

            // 初始化后更新展示区窗口大小
            this.$nextTick(() => {
                this.resizePlayerArea();
            });
        },
        destroyed() {
            this.$store.commit('web/SetIsShowLoading', true);
            
            // 重置数据加载标识
            this.$store.commit('web/ResetLoadDataDone');

            // egret舞台不支持重复加载，所以不需要重置egret舞台加载标识

            // 重置角色区
            this.$store.commit('web/ResetActorAreaData');

            // 重置数据区
            this.$store.commit('web/ResetDataAreaData');
            
            // 重置数据
            CoreData.reset();

            // 重置blockly
            Ide.dispose();

            window.removeEventListener("beforeunload",this.actBeforeUnload, false);
        },
        activated() {
        },
        deactivated() {
        },
        watch: {
            // 1.等待加载数据完成
            loadDataDone: function() {
                // 加载完数据后，初始化引擎
                // debug egret时，注释掉即可
                this.initEgret();
            },
            // 2.等待引擎加载完成
            loadStageDone: function () {
                // 加载舞台
                this.loadStage();
            },
        },
        computed: {
            ...mapGetters([
                'ide',
                'curActor',
                'isShowLoading',
                'isRunning',
                'isReset',
                'loadStageDone', 
                'loadDataDone', 
            ]),
            speedTitle() {
                return this.speedItems[this.selectedSpeedItem].title
            }
        },
        methods: {
            initHooker() {
                let that = this;

                // 注册对外接口, egret库载完成后调用
                window.setLoadStageDone = () => {
                    that.$store.commit('web/SetLoadStageDone');
                };
                // 外部接口：在引擎中选中Sprite时，同时在ActorArea选中
                window.selectSprite = (actorId) => {
                    // that.SelectSprite(actorId);
                    that.$store.dispatch('web/SelectSprite', actorId);
                };
                window.updateHistoryStatus = (showHistoryBack, showHistoryForward) => {
                    that.showHistoryBack = showHistoryBack;
                    that.showHistoryForward = showHistoryForward;
                };
                window.autoStop = () => {
                    //that.autoStop();
                };
                window.stop = () => {
                    that.stop();
                };
                // 用于响应竖版显示时，调整窗口大小resize，根据player-area的新高度height值，同步调整宽度width
                window.ideResize = () => {
                    that.resizePlayerArea();
                };
                window.curActorId = () => {
                    return that.curActor.id;
                };
                window.isShowLoading = () => {
                    return that.isShowLoading;
                };

                // 创建变量对话框
                window.myPrompt = (title, message, defaultValue, varType) => {
                    return that.myPrompt(title, message, defaultValue, varType);
                };
                // 创建函数对话框
                window.showCreateFuncDlg = function() {
                    that.$refs.refFuncDlg.show();
                };

                // 在展示区中吸取颜色功能
                window.resetFocus = (isSelected, color) => {
                    if (that.driver) {
                        that.driver.reset();
                    }
                    that.resetFocus(isSelected, color);
                },
                window.pickColor = () => {
                    return that.pickColor();
                }

                // 显示询问对话框
                window.showAskAndAnswerDlg = (id, objId, question) => {
                    that.showAskAndAnswerDlg(id, objId, question);
                },
                // 隐藏询问对话框
                window.hideAskAndAnswerDlg = () => {
                    that.hideAskAndAnswerDlg();
                }
            },
            initEgret() {
                if (egret) {
                    egret.runEgret({ renderMode: "webgl", audioType: 0, calculateCanvasScaleFactor:function(context) {
                        var backingStore = context.backingStorePixelRatio ||
                                context.webkitBackingStorePixelRatio ||
                                context.mozBackingStorePixelRatio ||
                                context.msBackingStorePixelRatio ||
                                context.oBackingStorePixelRatio ||
                                context.backingStorePixelRatio || 1;
                        return (window.devicePixelRatio || 1) / backingStore;
                    }});
                }
            },
            loadStage() {
                if (this.loadStageDone && this.loadDataDone) {

                    // 重置舞台（包括展示区、角色区等）
                    this.resetStage()

                    // 清理Scratch代码区
                    Ide.clearWorkspace();

                    // 加载命令栏
                    // 因为toolbox命令栏中使用了egret中的数据，所以需要等到egret初始化完毕后才能init
                    // TODO: this.showToolbox 需要单独处理一下
                    // 目前是在数据加载完成后，加载命令栏，暂时使用isShowLoading判断
                    if (this.isShowLoading) {
                        if (this.showToolbox) {
                            // 自定义工具栏（只包含特定命令）
                            let customToolbox = CoreData.getToolbox();
                            if (customToolbox != '') {
                                Ide.init('classroom', true, customToolbox);
                                // Ide.workspace.updateToolbox(customToolbox);
                            
                            } else {
                                Ide.init('ide');

                                let curData = CoreData.getCurData();
                                if (curData && Object.keys(curData.actors).length > 0) {
                                    // 加载角色命令
                                    CoreData.updateToolbox('actor');
                                } else {
                                    // 加载背景命令
                                    CoreData.updateToolbox('backdrop');
                                }
                            }
                        } else {
                            // console.log('TTTT')
                            Ide.init('ide', false);
                        }
                    }

                    // 将当前角色设置为背景
                    this.$store.commit('web/SetCurActor', 'backdrop');

                    // 加载背景
                    let backdrop = CoreData.getCurBackdrop();
                    // egret更新
                    window.backdropInstance.loadBackdrop(backdrop);

                    // 加载背景，确保backdrop中有图片才加载
                    let costume = CoreData.getCurCostume('backdrop');
                    if (costume) {
                        // 更新角色区
                        this.$store.commit('web/AddActorAreaData', {
                            id: 'backdrop',
                            name: '背景',
                            src: getImgUrl2(costume.id)
                        });

                        // 设置当前Actor
                        this.$store.commit('web/SetCurActor', 'backdrop');

                        // 加载一遍block，用于加载老代码中的变量
                        // TODO：此处后面可以根据meta数据的版本来判断是否需要加载
                        Ide.clearWorkspace();
                        if (!isEmptyObject(backdrop.block)) {
                            Ide.block2workspace(backdrop.block);
                        }
                    }

                    // 加载角色
                    let curData = CoreData.getCurData();
                    if (curData) {
                        let actors = []
                        
                        for (let id in curData.actors) {
                            // egret更新
                            window.spriteInstance.addSprite(CoreData.getCurActor(id), false, true);

                            // 保存角色区数据
                            let costume = CoreData.getCurCostume(id);

                            actors.push({
                                id: id,
                                name: curData.actors[id].meta.name,
                                src: getImgUrl2(costume.id),
                                index: curData.actors[id].meta.index,
                            })

                            // 设置当前Actor
                            this.$store.commit('web/SetCurActor', id);

                            // 加载一遍block，用于加载老代码中的变量
                            // TODO：此处后面可以根据meta数据的版本来判断是否需要加载
                            Ide.clearWorkspace();
                            // let actor = curData.actors[this.curActor.id];
                            let actor = CoreData.getCurActor(id);
                            if (actor) {
                                Ide.block2workspace(actor.block);
                            }
                        }

                        // 更新角色区
                        actors.sort(function (a, b) {
                            return a['index'] - b['index']
                        })
                        for (let i = 0; i < actors.length; i++) {
                            actors[i].index = i
                            this.$store.commit('web/AddActorAreaData', actors[i])
                        }
                        window.stageInstance.updateSpriteIndex(actors);
                    }

                    // 为命令栏补充全局变量
                    CoreData.paddingGlobalDatas();


                    // 同步到代码区
                    Ide.workspace2code();

                    this.$store.commit('web/SetIsShowLoading',false);

                    // CoreData.showData();
                }
            },
            resetStage() {
                // 停止当前运行的程序
                this.stop();

                // 重置舞台中的Sprite
                window.stageInstance.resetStage();
                
                // 更新角色区
                this.$store.commit('web/ResetActorAreaData');
            },
            // 切换舞台
            reloadStage() {
                if (this.loadStageDone && this.loadDataDone) {
                    // 重新加载舞台
                    this.loadStage();
                }
            },
            play() {
                this.$store.dispatch("web/PlayerStart");
            },
            autoStop() {
                this.$store.dispatch("web/PlayerAutoStop");
            },
            stop() {
                this.$store.dispatch("web/PlayerStop");
            },
            reset() {
                this.$store.dispatch("web/PlayerReset");
            },

            undo() {
                Ide.workspace.undo(false);
            },
            redo() {
                Ide.workspace.undo(true);
            },

            // 竖版中，更新展示区宽度，设置为高度的70%
            resizePlayerArea() {
                if (this.$refs && this.$refs.playerArea) {
                    this.leftAreaWidth = this.$refs.playerArea.offsetHeight * 0.7;
                }
            },
            toggleRuler() {
                window.stageInstance.toggleRuler();
            },
            toggleMousePos() {
                window.stageInstance.toggleMousePos();
            },
            myPrompt(title, message, defaultValue, varType) {
                this.$refs.refVarDlg.showDlg(title, message, defaultValue, varType).then(result => {
                    //console.log(result);
                    result.id = this.curActor.id;
                    this.resolve(result);
                }).catch(() => {
                    //
                });
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },

            // 展示区中选取颜色功能
            pickColor() {
                if (!this.driver) {
                    // this.driver = new Driver();
                    this.driver = new Driver({
                        className: "scoped-class", // className to wrap driver.js popover
                        animate: true, // Animate while changing highlighted element
                        opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
                        // padding: 10, // Distance of element from around the edges
                        padding: 0,
                        allowClose: false, // Whether clicking on overlay should close or not
                        overlayClickNext: false, // Should it move to next step on overlay click
                        doneBtnText: "完成", // Text on the final button
                        closeBtnText: "关闭", // Text on the close button for this step
                        nextBtnText: "下一步", // Next button text for this step
                        prevBtnText: "上一步", // Previous button text for this step
                        // Called when moving to next step on any step
                        onReset: (Element) => {
                            window.stageInstance.pickColorEnd(false);
                        },
                    });
                }
                this.driver.highlight('#player-area');

                window.stageInstance.pickColorStart();

                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            resetFocus(isSelected, color) {
                if (isSelected) {
                    this.resolve(color);
                } else {
                    this.reject();
                }
            },
            actBeforeUnload(event){
                event.returnValue = "确定要离开当前页面吗？";
            },
            showAskAndAnswerDlg(id, objId, question) {
                this.$refs.askAndAnswerDlg.openDlg(id, objId, question);
            },
            hideAskAndAnswerDlg() {
                this.$refs.askAndAnswerDlg.hideDlg();
            },
            submitAnswer(params) {
                window.stageInstance.setAnswer(params.id, params.objId, params.answer);
            },
            clickSpeedMenu() {
                let speed = 10
                if (this.selectedSpeedItem == 0) {
                    speed = 1000
                } else if (this.selectedSpeedItem == 1) {
                    speed = 100
                } else if (this.selectedSpeedItem == 2) {
                    speed = 10
                } else if (this.selectedSpeedItem == 3) {
                    speed = 1
                }
                Runner.setSpeed(speed)
            }
        },
        components: {
            PropertyArea,
            DataArea,
            CmdMask,
            // RunningMask,
            VariableDlg,
            FunctionDialog,
            MediaLib,
            Configure,
            DeleteDialog,
            AskAndAnswerDlg,
        }
    }

</script>

<style lang="scss" scoped>
.main-area {
    width: 100%;
    height: 100%;
    display: flex;

    // 竖版样式
    &.forV {
        bottom: 110px;
        border-bottom: 3px solid #3d3f44;
    }

    .left-area {
        width: 450px;
        //min-width: 20%;
        //max-width: 40%;
        //max-width: 600px;
        //min-width: 350px;
        //height: 100%;
        bottom: 0;
        background-color: #525357;
        display: flex;
        flex-direction: column;
        z-index: 102;
        position: relative;

        .player-area {
            height: 0;
            width: 100%;
            padding-bottom: 142%;
            /*宽高比70%*/
            overflow: hidden;
            position: relative;
            background: #ffffff;
            background-image:
                linear-gradient(45deg, #efefef 25%, transparent 0, transparent 75%, #efefef 0),
                linear-gradient(45deg, #efefef 25%, transparent 0, transparent 75%, #efefef 0);
            background-position: 0 0, 15px 15px;
            background-size: 30px 30px;

            &.forV {
                height: 100%;
                padding-bottom: unset;
            }

            .egret-player {
                margin: 0;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                // width: 100%;
                // height: 100%;
                overflow: hidden;
                position: absolute;
            }
        }

        .cmd-area {
            width: 100%;
            height: 40px;
            min-height: 40px;
            // background-color: #33373a;
            background-color: #404040;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;

            .cmd-left {
                height: 100%;
                display: flex;
                align-items: center;
                padding-left: 8px;

                .cmd-item {
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 8px;

                    img {
                        height: 18px;
                        margin-right: 5px;
                    }

                    .text {
                        font-size: 14px;
                        color: #e6e6e6;
                    }
                }

                .cmd-item:hover {
                    cursor: pointer;
                    background: #515151;
                }
            }

            .btn-play {
                //height: 35px;
                padding: 5px 13px;
                margin: 0 10px 0 0;
                border-radius: 20px;
                background-color: #35b06a;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 16px;
                    margin-right: 8px;
                }

                .text {
                    font-size: 14px;
                    font-weight: bold;
                    color: #fff;
                }
            }

            .btn-play:hover {
                cursor: pointer;
            }

            .btn-stop {
                //height: 35px;
                padding: 5px 13px;
                margin: 0 10px 0 0;
                border-radius: 20px;
                background-color: #ce401c;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 16px;
                    margin-right: 8px;
                }

                .text {
                    font-size: 14px;
                    font-weight: bold;
                    color: #fff;
                }
            }

            .btn-stop:hover {
                cursor: pointer;
            }

            .btn-reset {
                height: 35px;
                padding: 0 20px;
                background-color: #0094ca;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 20px;
                    margin-right: 8px;
                }

                .text {
                    font-size: 16px;
                    font-weight: bold;
                    color: #fff;
                }
            }

            .btn-reset:hover {
                cursor: pointer;
            }
        }
    }

    .left-right-border {
        left: 0;
        height: 100%;
        width: 5px;
        background-color: #3d3f44;
        position: absolute;
        top: 0;
        cursor: col-resize;
        z-index: 101;

        // 竖版中不需要调整大小，直接不显示即可
        &.forV {
            height: 0;
            //bottom: 110px;
        }
    }

    /*右侧区域，包括blockly区、代码区*/
    .right-area {
        height: 100%;
        flex: 1;
        //min-width: 60%;
        //max-width: 80%;
        //width: 100%;
        //min-width: 60%;
        /*background-color: gold;*/
        background-color: #525357;

        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        /*padding-left: 10px;*/
        position: relative;

        .blockly-area {
            /*float: right;*/
            height: 100%;
            width: 100%;
            position: relative;

            .cur-obj {
                position: absolute;
                z-index: 10;
                top: 12px;
                // left: 365px;
                left: 215px;
                color: #e6e6e6;
                font-size: 14px;
                background-color: #33373a;
                padding: 3px 12px;
                border-radius: 5px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .text {
                    margin-left: 5px;
                }
            }

            .history {
                position: absolute;
                z-index: 10;
                top: 6px;
                right: 16px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .btn-history {
                    cursor: pointer;
                }

                img {
                    height: 30px;
                    width: 30px;
                    margin: 0 2px;
                }
            }
        }

        .blockly-div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: 0;
            /*visibility: hidden;*/
        }

        .code-area {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: black;
            z-index: 100;

            .code-editor {
                margin: 0;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                line-height: 25px;
            }
        }
    }
}
</style>


<style lang="scss">
/******************************
 * 命令栏改版前样式
 ******************************/

/*积木代码区背景色*/
.blocklySvg {
  background-color: #525357 !important;
  border: 0px solid black;
}
/*去掉blockly区上侧及右侧白边*/
.blocklyMainBackground {
  stroke-width: 0 !important;
}

.blocklyTooltipDiv {
  /*padding-top: 100px;*/
  /*margin-top: 30px;*/
  /*padding: 30px 0 0 0 !important;*/
  top: 100px !important;
}

/*flyout背景色*/
.blocklyFlyoutBackground {
  fill: #666;
  fill-opacity: .75;
  //fill: #57585d;
  //fill-opacity: .8;
}

/*toolbox相关*/
.blocklyToolboxDiv {
  background-color: #57585d;
  padding-top: 8px;
  padding-left: 4px;
}
.blocklyTreeRow {
  height: auto;
  line-height: 22px;
  white-space: nowrap;
  background-color: #ece8e5;
  margin: 0 8px 8px 8px;
  //padding: 5px 10px 5px 5px !important;
  padding: 4px 3px 4px 0 !important;
  border-radius: 3px;
  text-align: center;
  width: 80px;
}
.blocklyTreeRow:hover {
  cursor: pointer;
}
.blocklyTreeLabel {
  font-size: 15px;
  padding: 0;
}
.blocklyTreeLabel:hover {
  cursor: pointer;
}

.blocklyTreeIcon {
  background-image: none;
  height: 0;
  width: 0;
  vertical-align: middle;
}

.scratchCategoryMenu {
  width: 85px;
}

//.blocklyToolboxDiv {
.blockly-div {
  //position: relative;
  .ToolboxMaskDiv {
    height: 0;
    position: absolute;
  }
  .toolboxMask {
    //display: none;
    z-index: 21;
    position: absolute;
    top: 0;
    //left: 0;
    left: 85px;
    //right: 0;
    bottom: 0;
    width: 260px;
    height: auto;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    .toolboxTrashCan {
      height: 80px;
      width: 80px;
      background-image: url("/images/ide/trashcan.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    .toolboxTrashCanOpen {
      height: 80px;
      width: 80px;
      background-image: url("/images/ide/trashcan_open.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}


// 隐藏右键菜单中的纵向滚动条
.blocklyWidgetDiv .goog-menu {
//   z-index: 999;
    overflow-y: hidden;
}

/******************************
 * 命令栏改版后样式
 ******************************/

.blocklyToolboxDiv {
  //background-color: #404040;
  background-color: #555;
  //padding-left: 2px;
  //padding-right: 2px;
  padding: 0 0;
  z-index: 10;

  .scratchCategoryMenu {
    //background: #404040;
    background: #555;

    .scratchCategoryMenuRow {
      .scratchCategoryMenuItem {
        font-size: 13px;
        color: #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.75rem 0;
        &.categorySelected {
          background: #474747;
        }

        .scratchCategoryItemBubble {
          width: 10px;
          height: 10px;
          margin: 0 8px 0 0;
        }
        .scratchCategoryMenuItemLabel {
          font-size: 13px;
          color: #efefef;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.blocklyFlyoutBackground {
  fill: #474747;
  fill-opacity: 1;
}
.blocklyFlyoutLabelText {
//   fill: #aaa;
  fill: #cdcdcd;
}



// 调整变量/函数中的按钮颜色
.blocklyFlyoutButton .blocklyText {
    fill: #eee;
    font-size: 13pt;
}
.blocklyFlyoutButtonBackground {
    stroke: #333;
}
.blocklyFlyoutButton {
    fill: #333;
}
.blocklyFlyoutButton:hover {
    fill: #ff8400;
    // fill: unset;
    // stroke: #ff8400;
}


// 积木输入框（如输入函数名的地方）的颜色
.blocklyHtmlInput {
    color: #2a2c33;
}
// .removableTextInput {
//     .blocklyHtmlInput {
//         color: #eee;
//     }   
// }

.blocklyScrollbarHandle {
    width: 0 !important;
}


/******************************
 * 代码编辑器相关
 ******************************/

/*代码编辑器相关*/
.code-area .code-editor {
  line-height: 25px;
}

/*优化编辑器样式配色*/
.ace-tomorrow-night-eighties {
  background-color: #272822 !important;
  color: #CCCCCC
}
.ace-tomorrow-night-eighties .ace_gutter {
  background: #2F3129 !important;
  color: #8F908A !important;
}
.ace-tomorrow-night-eighties .ace_print-margin {
  width: 1px;
  background: #2F3129 !important;
}
.ace-tomorrow-night-eighties.ace_multiselect .ace_selection.ace_start {
  box-shadow: 0 0 3px 0px #272822 !important;
}

/*优化编辑器样式配色*/
.ace-monokai {
  background-color: #1f1e2e !important;
  color: #CCCCCC
}
.ace-monokai .ace_gutter {
  background: #1f1e2e !important;
  color: #8F908A !important;
}
.ace-monokai .ace_gutter-active-line {
  background-color: #ffc107;
}
.ace-monokai .ace_print-margin {
  width: 1px;
  background: #2F3129 !important;
}
.ace-monokai.ace_multiselect .ace_selection.ace_start {
  box-shadow: 0 0 3px 0px #272822 !important;
}

.ace_scrollbar-v {
  overflow-y: hidden !important;
}
</style>