<template>
    <!--创建函数对话框-->
    <div class="functionDialog" v-bind:style="{ visibility: visibility }">
        <div
            class="screen-mask"
            v-show="visibility == 'visible'"
            @click.stop="cancel()"
        ></div>
        <div class="dialog">
            <div class="header">
                <div class="title">新建函数</div>
                <div class="window-close" @click.stop="cancel()">
                    <img src="@/assets/images/common/close.svg" />
                </div>
            </div>

            <!-- <div class="row"> -->
            <div id="blockly-func-div" class="blockly-func-div"></div>
            <!-- <div id="blockly-func-div" style="height: 300px; width: 300px;"></div> -->
            <!-- </div> -->
            <div class="content">
                <div class="row between">
                    <div class="btn-input" @click.stop="addTextNumber">
                        <div>添加</div>
                        <div>文本或数字</div>
                    </div>
                    <div class="btn-input" @click.stop="addBoolean">
                        <div>添加</div>
                        <div>布尔值</div>
                    </div>
                    <div class="btn-input" @click.stop="addLabel">
                        <div>添加</div>
                        <div>文本标签</div>
                    </div>
                </div>
            </div>
            <div class="btn-row">
                <div class="cancel" @click.stop="cancel()">取消</div>
                <div class="submit" @click.stop="submit()">确定</div>
            </div>
        </div>
    </div>
</template>

<script>
import Ide from '@/libs/ide/ide.js';
import { mapGetters } from "vuex";

export default {
    name: "functionDialog",
    data: function () {
        return {
            scope: "local",
            visibility: "hidden",
        };
    },
    computed: {
        ...mapGetters([
            "ide"
        ]),
    },
    created() {},
    mounted() {},
    activated() {},
    watch: {
        visibility: function() {
            if (this.visibility == "visible") {
                window.addEventListener('keydown', this.keyDown)
            } else {
                window.removeEventListener('keydown', this.keyDown)
            }
        }
    },
    methods: {
        show() {
            this.visibility = "visible";
        },
        submit() {
            var mutation = Ide.mutationRoot.mutationToDom(
                /* opt_generateShadows */ true
            );
            // console.log(mutation);

            Ide.mutationCallback(mutation);

            Ide.mutationCallback = null;
            Ide.mutationRoot = null;

            Ide.declarationWorkspace.clear();
            Ide.workspace.refreshToolboxSelection_();

            this.visibility = "hidden";
        },
        addTextNumber() {
            Ide.mutationRoot.addStringNumberExternal();
            Ide.declarationWorkspace.scrollCenter();
        },
        addBoolean() {
            Ide.mutationRoot.addBooleanExternal();
            Ide.declarationWorkspace.scrollCenter();
        },
        addLabel() {
            Ide.mutationRoot.addLabelExternal();
            Ide.declarationWorkspace.scrollCenter();
        },
        cancel() {
            Ide.mutationCallback = null;
            Ide.mutationRoot = null;

            Ide.declarationWorkspace.clear();
            Ide.workspace.refreshToolboxSelection_();

            this.visibility = "hidden";
        },
        keyDown(e) {
            // e.preventDefault()
            if (e.key == 'Escape') {
                this.cancel()
            }
        },
    },
};
</script>

<style lang="scss">
.functionDialog {
    .blocklyMainBackground {
        fill: #f5f5f5 !important;
    }
}
</style>

<style lang="scss" scoped>
.functionDialog {
    .screen-mask {
        position: fixed;
        z-index: 900;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.75);
        opacity: 1;
        visibility: visible;
    }

    .dialog {
        position: fixed;
        z-index: 999;
        display: flex;
        flex-direction: column;
        top: 25%;
        left: 50%;
        //margin-top: -250px;
        margin-left: -300px;
        background: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        // width: 600px;

        .header {
            background: #1867c0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            height: 50px;

            .title {
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                letter-spacing: 1px;
            }

            .window-close {
                position: absolute;
                // top: 0;
                right: 5px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px;
                border-radius: 30px;

                img {
                    height: 14px;
                    width: 14px;
                }
            }

            .window-close:hover {
                background-color: #416fc4;
            }
        }

        .blockly-func-div {
            width: 600px;
            height: 180px;
        }

        .content {
            display: flex;
            flex-direction: column;
            padding: 30px 30px 10px 30px;
            margin: 0 30px;
        }

        .btn-input {
            color: #333;
            padding: 15px 25px;
            // margin: 0 15px;
            width: 140px;
            border-radius: 8px;
            // border: 3px solid #d4e2fc;
            border: 3px solid #d9e6fe;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .btn-input:hover {
            background-color: #f0f4fd;
        }

        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            &.left {
                justify-content: flex-start;
            }

            &.right {
                justify-content: flex-end;
            }

            &.between {
                justify-content: space-between;
            }
        }
        .btn-row {
            display: flex;
            justify-content: flex-end;
            margin: 0 15px 15px 15px;
        }

        // .varLabel {
        //     font-size: 16px;
        //     font-weight: bold;
        // }

        // input[type="text"] {
        //     outline: none;
        //     border: 1px solid #dbdbdb;
        //     border-radius: 4px;
        //     width: 250px;
        //     height: 30px;
        //     font-size: 16px;
        //     padding: 0 5px;
        // }

        // .radioLabel {
        //     font-size: 16px;
        // }

        // input[type="radio"] {
        //     width: 20px;
        //     height: 20px;
        //     margin-right: 5px;
        // }

        .cancel {
            padding: 8px 15px;
            border-radius: 4px;
            color: #1867c0;
            cursor: pointer;
        }

        .cancel:hover {
            background-color: #f0f4fd;
        }

        .submit {
            padding: 8px 15px;
            color: #1867c0;
            border-radius: 4px;
            cursor: pointer;
        }

        .submit:hover {
            background-color: #f0f4fd;
        }
    }
}
</style>