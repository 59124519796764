<template>
    <!--添加角色窗口-->
    <div v-show="show" class="askDlgContainer">
        <v-container>
            <div class="inputDlgWrapper">
                <div class="question" v-if="question != ''">
                    {{ question }}
                </div>
                <div class="inputDlg">
                    <v-text-field
                        v-model="inputText"
                        type="text"
                        single-line
                        placeholder="在此输入结果"
                        clear-icon="mdi-close-circle"
                        clearable
                        outlined
                        rounded
                        autofocus
                        color="green"
                        hide-details="true"
                        @keydown="keydown"
                    ></v-text-field>
                    <v-btn
                        color="success"
                        fab
                        dark
                        height="48"
                        width="48"
                        class="ml-3"
                        @click.stop="confirm"
                    >
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "askAndAnswerDlg",
    data: function () {
        return {
            show: false,

            id: '',
            objId: '',
            question: '',
            inputText: '',
        };
    },
    methods: {
        openDlg(id, objId, question) {
            this.id = id
            this.objId = objId
            this.question = question
            this.inputText = ''

            this.show = true
        },
        hideDlg() {
            this.id = ''
            this.objId = ''
            this.question = ''
            this.inputText = ''

            this.show = false
        },
        confirm() {
            this.show = false

            this.$emit("submit", { 
                id: this.id, 
                objId: this.objId, 
                answer: this.inputText 
            });
        },
        keydown(e) {
            if (e.key == 'Enter') {
                this.confirm()
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.askDlgContainer {
    z-index: 3000;
    width: 100%;
    height: 100%;
    position: relative;

    .inputDlgWrapper {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 10px;
        padding-top: 5px;
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 8px;

        .question {
            width: 100%;
            height: 30px;
            margin-top: 15px;
            padding: 0 20px;
            color: #555;
            font-size: 16px;
            // font-weight: bold;
            letter-spacing: 1px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .inputDlg {
            // position: absolute;
            // left: 0;
            // right: 0;
            // bottom: 0;
            height: 80px;
            width: 100%;
            padding: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>
